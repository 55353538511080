import React, {useContext} from "react";
import {LoadContext} from "../../hoc/LoadProvider";
import "../../index.css";

export default function LoadingScreen() {
    // const {setFirstview} = useContext(LoadContext);    
    localStorage.setItem("firstview", false);
    // setFirstview(false);
    return (
        <div className="loadingscreen-container">
            <img src="logo192.png" style={{animation: 'spinner 2s linear infinite'}} alt="AAC" width="auto" height="100"/>
        </div>
    );
}