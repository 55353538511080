import React from "react";
import "../../index.css";
import {$authHost} from "../../http";

export  const LoadingProgress = ({uuid, SuccessClean, setIsProgress}) => {

    console.log(uuid);



    async function Progress_alert(uuid){
        var Response_message=''
        var Response_progress=0
        if(uuid!=null){

            // while (1) {
            //     const data = await $authHost.get(`/task-status-get?task=${uuid}`).then(response => {
            //         Response_message=response.data.status
            //         Response_progress=response.data.progress
            //     });
            //     const bar = document.getElementById('query_progress');
            //     bar.style.width = String(Response_progress)+"%";
            //     if (Response_message === "queued") {
            //         const mess = document.createElement('div');
            //         mess.innerHTML = [
            //             `<div id="status_mess">Ваш запрос в очереди....</div>`,
            //         ].join('')
            //         document.getElementById('before_status').after(mess);
            //     }
            //     if (Response_message === "started") {
            //         document.getElementById('status_mess').remove();
            //     }
            //     if (Response_message === "failed") {
            //         document.getElementById('alert_progress').remove();
            //         const appendAlert = () => {
            //             const wrapper = document.createElement('div')
            //             wrapper.innerHTML = [
            //                 `<div class="alert alert-danger alert-dismissible fade show mt-3" role="alert">`,
            //                 `   <div>Призошла ошибка сохранения...</div>`,
            //                 '   <button type="button" class="btn-close" data-bs-dismiss="alert" onclick="" aria-label="Close"></button>',
            //                 '</div>'
            //             ].join('')
            //             document.getElementById('progress-wrapper').appendChild(wrapper);
            //         }
            //         appendAlert();
            //     }
            //     if (Response_message === "finished")
            //     {
            //         localStorage.setItem("changes", String(0));
            //         // setSubstitutionAdd([]);
            //         // setReferenceAdd([]);
            //         SuccessClean();
            //         break;
            //     }
            //     await new Promise(r => setTimeout(r, 2000));
            // }
            await new Promise(r => setTimeout(r, 2000));
            SuccessClean();
            document.getElementById('alert_progress').remove();
            setIsProgress(false);
        }

    }


    Progress_alert(uuid);

    return (
        <div className="progress-container">
            <div className="progress-wrapper">
                <div className="alert alert-success alert-dismissible fade show mt-3" id="alert_progress" role="alert">
                 <div id="before_status"><strong>Сохранение изменений</strong></div>
                 <div className="progress">
                     <div className="progress-bar bg-success progress-bar-striped progress-bar-animated" role="progressbar" id="query_progress" style={{width: '5%'}}/>
                    </div>
                </div>

            </div>
        </div>
    );
}