import Login from './pages/Loginpage'
import {Routes, Route} from 'react-router-dom'
import Anomaly from './pages/Anomalypage';
import Notfoundpage from './pages/Notfoundpage';
import {RequareAuth} from './hoc/RequareAuth'
import {AuthProvider} from './hoc/AuthProvider'
import Manager from "./pages/Managerpage";
import React from "react";
import {AdminAuth} from "./hoc/AdminAuth";
import {ModalProvider} from "./hoc/ModalProvider";
import {LoadProvider} from "./hoc/LoadProvider";
import "bootstrap-icons/font/bootstrap-icons.css"



function App() {

  return (
        <AuthProvider>
            <LoadProvider>
                <ModalProvider>
                    <div className='container'>
                        <Routes>
                            <Route path='/' element={<Login/>}/>
                            <Route path='/anomaly' element={
                                <RequareAuth>
                                    <Anomaly/>
                                </RequareAuth>
                            }/>
                            <Route path='/manager' element={
                                <AdminAuth>
                                    <Manager/>
                                </AdminAuth>
                            }/>
                            <Route path='*' element={<Notfoundpage/>}/>
                        </Routes>
                    </div>
                </ModalProvider>
            </LoadProvider>
        </AuthProvider>
  );
}

export default App;
