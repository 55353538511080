import React, {createContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {TimeoutWarningModal} from "../components/modals/timeoutWarningModal";

export const AuthContext = createContext(null);
const storageUser = 'userData';
const storageSession = 'sessionData';
const storageWarning = 'warningData';



export const AuthProvider = ({children})=>{

    const navigate = useNavigate();

    const [isWarningModalOpen, setWarningModalOpen] = useState(false);
    const [isInitAuth, setIsInitAuth] = useState(false);

    const [user, setUser] = useState(()=>
    {
        const data = JSON.parse(localStorage.getItem(storageUser))

        if(data && data.user)
        {
            return data.user;
        }
        else return null;
    });

    const [token, setToken] = useState(()=>{
        const data = JSON.parse(localStorage.getItem(storageUser))
        if(data && data.token)
        {
            return data.token;
        }
        else return null;
    });


    const signIn = (User, token) => {
        setUser(User);
        setToken(token);
        localStorage.setItem(storageUser, JSON.stringify({
            user: User, token: token
        }));
        var now = new Date().getTime();
        localStorage.setItem(storageSession, JSON.stringify({
            startTime: now
        }));
        localStorage.setItem("changes", "0");
        setIsInitAuth(true);
    }


    const signOut = (cb)=>{
        setUser(null);
        setToken(null);
        localStorage.clear();
        setWarningModalOpen(false);
        cb();
    }



    const value = {user, token, signIn, signOut}


    useEffect(() => {
        const standartTime = 9900000;
        var _time = 9900000;
        const extraTime = 900000;
        var _extratime = 900000;

        const createTimeout1 = () => setTimeout(()=>{
            let _storageWarning_data = JSON.parse(localStorage.getItem(storageWarning))
            console.log(_storageWarning_data);
            if(_storageWarning_data === null){
                setWarningModalOpen(true);
                localStorage.setItem(storageWarning, JSON.stringify({
                    modalWarning: "true"
                }));
            }
            //var _now_extraTime = new Date().getTime();
            //localStorage.setItem('startExtraTime', _now_extraTime);
        }, _time);

        const createTimeout2 = () => setTimeout(() => {
            // Implement a sign out function here
            signOut(()=>{navigate('/')})
        }, _extratime);//для демо
        // }, standartTime/11)//для большой игры


        // Initialization
        var storageSession_data = JSON.parse(localStorage.getItem(storageSession))
        if(storageSession_data) {
            let now = new Date().getTime();
            _time = standartTime - (now - storageSession_data.startTime);
            // var data_extraTime = localStorage.getItem('startExtraTime');
            // _extratime = extraTime - (now-data_extraTime);
            let _storageWarning_data = JSON.parse(localStorage.getItem(storageWarning))
            if (_storageWarning_data) {
                createTimeout2();
            } else
            {
                createTimeout1();
            }
        }

        return () => {
        }
    },[isWarningModalOpen, isInitAuth])
    return (
        <AuthContext.Provider value={value}>
            {children}

            {isWarningModalOpen &&
                <TimeoutWarningModal
                    isOpen={isWarningModalOpen}
                    onRequestClose={()=>{setWarningModalOpen(false)}}/>
            }
        </AuthContext.Provider>
    )
}