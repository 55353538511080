import React, {useCallback, useContext, useEffect, useRef, useState,} from 'react';
import Anomalylist from "../components/anomalyList";
import ReferenceList from "../components/referenceList";
import NewNavBar from "../components/UI/NewNavBar";
import {ExportCSV} from "../components/excelExport";
import {ImportCSV} from "../components/excelImport";
import Modal from "../modal/Modal";
import {$authHost} from "../http";
import {useAuth} from "../hook/useAuth";
import {LoadContext} from "../hoc/LoadProvider";
import $ from 'jquery';
import Select from 'react-select';
import AsyncCreatableSelect from "react-select/async-creatable";
import Dropzone from "../components/Dropzone/Dropzone";


const AnomalyShort=()=>{

    const {user} = useAuth();
    const {setIsLoading} = useContext(LoadContext);

    const [anomalys, setAnomalys] = useState([]);
    const [modalActive, setModalActive] = useState(false);
    const [loadActive, setLoadActive] = useState(true); //активация кнопки доп загрузки аномалий
    const [selectedAnomaly, setSelectedAnomaly] = useState(null);
    const [referenceAdd, setReferenceAdd] = useState([]); //добавляем новые хорошие значения
    const [substitutionAdd, setSubstitutionAdd] = useState([]); //добавляем новые пары для словаря
    const [checkInput, setCheckInput] = useState(false);
    const [UserChoice, setUserChoice] = useState(null);
    const [anomalysCount, setAnomalysCount] = useState(0);

    window.onbeforeunload = function() {
        if(substitutionAdd.length+referenceAdd.length>0){
            return false;
        }
    };



    const getAnomalys = useCallback(async ()=>{
        try{
            setIsLoading(true);
            const fetched = await $authHost.get(`/anomalys?user_id=${user.user_ID}`).then(res => {
                if(res.data.status_code === 500){
                    setAnomalys([]);
                }
                else {
                    const anomalys = res.data;
                    setAnomalys(anomalys);
                    setSelectedAnomaly(anomalys[0].Product_Name)
                    setLoadActive(true)
                }
            }).catch().finally( ()=>{setIsLoading(false);})
        } catch (e){
        }
    }, [])

    const getCountAnomalys = useCallback(async ()=>{
        try{
            const fetched = await $authHost.get(`/anomalys-count?user_id=${user.user_ID}`).then(res =>{
                if(res.data.status_code === 500){
                    setAnomalysCount(0);
                }
                else {
                    const anomalys_data = res.data;
                    if(anomalys_data>300){
                        setAnomalysCount(anomalys_data-300);
                    }else
                        setAnomalysCount(0);
                }
            })
        }catch (e) {

        }
    }, [])

    const bindHandler = () => {
        var reference_good = document.getElementById("reference_good");
        if(!!UserChoice){
            reference_good.classList.remove("is-invalid");
            var productName = UserChoice.value;
            const regEx = (str) => /.{2,255}$/i.test(str);
            if(regEx(productName)){
                reference_good.classList.remove("is-invalid");
                const addSubs = [...substitutionAdd, {
                    Product_Name: productName,
                    Product_Name_Anomaly: selectedAnomaly
                }]
                setSubstitutionAdd(addSubs);
                var data = JSON.parse(localStorage.getItem('changes'));
                var new_change = Number(data) + 1;
                localStorage.setItem("changes", String(new_change));

                let anomalysTrim = anomalys.filter(item => item.Product_Name !== selectedAnomaly)
                setAnomalys(anomalysTrim);
                if (anomalysTrim.length != 0) {
                    setSelectedAnomaly(anomalysTrim[0].Product_Name);
                } else {
                    setLoadActive(false);
                    setSelectedAnomaly(null);
                }
            }else {
                reference_good.classList.add("is-invalid");
                reference_good.classList.remove("is-valid");
            }
        }else{
            reference_good.classList.add("is-invalid");
            reference_good.classList.remove("is-valid");
        }
    }
    const addHandler = ()=> {
        if (!!selectedAnomaly) {
            const addGood = [...referenceAdd, selectedAnomaly]
            setReferenceAdd(addGood)
            var data = JSON.parse(localStorage.getItem('changes'));
            var new_change = Number(data) + 1;
            localStorage.setItem("changes", String(new_change));
            let anomalysTrim = anomalys.filter(item => item.Product_Name !== selectedAnomaly)
            setAnomalys(anomalysTrim);
            if (anomalysTrim.length != 0) {
                setSelectedAnomaly(anomalysTrim[0].Product_Name);
            } else {
                setLoadActive(false)
                setSelectedAnomaly(null);
            }
        }
    }
    const loadAnomaly = ()=> {
        getAnomalys()
        setLoadActive(true)
    }

    const changeAnListhandler = (event) =>{
        var index = event.nativeEvent.target.selectedIndex;
        let a = event.nativeEvent.target[index].text;
        setSelectedAnomaly(a);
    }


    const filterColors = async (inputValue) => {
        var array = [];
        const fetched = await $authHost.get(`/reference?search=${inputValue}`).then(res => {
            const references = res.data;
            references.map((reference, index)=> {
                array.push({ value: reference, label: reference })
            })
        })
        return array;
    };


    async function promiseOptions(inputValue){
        return await filterColors(inputValue);
    }



    useEffect( () =>{
        getAnomalys()
        getCountAnomalys()
    }, [getAnomalys, getCountAnomalys])




    return (
        <>
           <NewNavBar/>
            <div className="row">
               <div className="mx-auto" style={{height:'70vh', marginTop: '10vh'}}>
                   <div className="row px-5 py-2" style={{height:'100%'}}>
                       <div className="col-12 text-bg-light rounded shadow-sm px-1 py-3 position-relative">
                           <div className="row mb-2">
                               <div className="col-6">
                                   <h4 className="text-center">Аномалии</h4>
                               </div>
                               <div className="col-6">
                                   <h4 className="text-center self-align-start">Эталоны</h4>
                               </div>
                           </div>
                           <form>
                                <div className="hstack gap-2">
                                   <div className="px-4" style={{width: '50%'}}>
                                       <div className="d-grid gap-3 d-md-block mb-3">
                                           <button type="button" className="btn btn-sm btn-outline-success rounded-pill px-2" style={{cursor: 'default'}} >
                                               В базе: <span className="badge rounded-pill text-bg-success px-2">{anomalysCount}</span>
                                           </button>
                                           <button type="button" className="btn btn-sm btn-outline-secondary rounded-pill px-2 mx-2" style={{cursor: 'default'}}>
                                               На странице: <span className="badge rounded-pill text-bg-secondary px-2">{anomalys.length}</span>
                                           </button>
                                           <button type="button" className="btn btn-sm btn-outline-dark rounded-pill px-2" >
                                               В проверке: <span className="badge rounded-pill text-bg-dark px-2">{substitutionAdd.length+referenceAdd.length}</span>
                                           </button>
                                       </div>
                                       <p className="h6 mt-0 mb-2 mt-3">Выберите аномальное значение:</p>
                                       <div className="form-select-lg-mb-3" style={{position: 'relative'}}>
                                           {anomalysCount > 0 && anomalys.length === 0 && substitutionAdd.length === 0 && referenceAdd.length === 0 &&
                                           <div className="card w-75 text-secondary"
                                                style={{position: 'absolute', top: '15%', left: '10%', border: 'none' }}>
                                                   <div className="card-body">
                                                       <h5 className="card-title">Сохранено</h5>
                                                       <p className="card-text">Текущие изменения сохранены, вы можете загрузить следующий набор аномалий</p>
                                                       <button type="button" className="btn btn-sm btn-secondary px-3" name="load_anomaly"
                                                          id="load_anomaly" value='1' onClick={loadAnomaly} disabled={loadActive} >Загрузить еще</button>
                                                   </div>
                                               </div>
                                           }
                                           {anomalysCount >= 0 && anomalys.length === 0 && substitutionAdd.length+referenceAdd.length > 0  &&
                                           <div className="card w-75 text-secondary"
                                                style={{position: 'absolute', top: '20%', left: '10%', border: 'none' }}>
                                                   <div className="card-body">
                                                       <h5 className="card-title">Проверьте изменения</h5>
                                                       <p className="card-text">Проверьте и сохрание текущие изменения для загрузки следующего набора аномалий</p>
                                                   </div>
                                               </div>
                                           }
                                           {anomalysCount === 0 && anomalys.length === 0 && substitutionAdd.length+referenceAdd.length === 0 &&
                                               <div className="card w-75 text-secondary"
                                                    style={{position: 'absolute', top: '20%', left: '10%', border: 'none' }}>
                                                   <div className="card-body">
                                                       <h5 className="card-title">Нет доступных аномалий</h5>
                                                       <p className="card-text">На данный момент, в базе данных нет неотсортированных аномалий</p>
                                                   </div>
                                               </div>
                                           }
                                           <select required name="anomaly_good" className="w-100 form-control"
                                                   id="anomaly_good" size="10" onChange={changeAnListhandler}>
                                               <Anomalylist anomalys={anomalys} selected={selectedAnomaly}/>
                                           </select>
                                       </div>
                                        <div className="row" style={{height:'10%'}}>
                                            <div className="col-12 d-flex justify-content-start px-3">
                                                <button type="button" disabled={!anomalys.length>0}
                                                        className="btn col-6 btn-outline-secondary mt-3" name="add_to_dictionary"
                                                        id="add_to_dictionary" value='1' onClick={addHandler}>
                                                    Добавить в словарь
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vr p-0"/>
                                    <div className="px-4" style={{width: '50%'}}>
                                        <div className="row">
                                            <div>
                                                <p className="h6">Выберите эталонное значение:</p>
                                                <AsyncCreatableSelect
                                                    cacheOptions
                                                    defaultOptions
                                                    loadOptions={promiseOptions}
                                                    onChange={(choice) => setUserChoice(choice)}
                                                    placeholder={'Выберите эталон...'}
                                                    formatCreateLabel={(inputText) => `Добавить "${inputText}"`}
                                                    id="reference_good" name="reference_good"
                                                />
                                                <div className="invalid-feedback">
                                                    Необходимо указать эталон
                                                </div>

                                           </div>
                                           <div className="">
                                               <button type="button"
                                                       className="btn col-6 btn-outline-secondary mt-3" name="add_new_good" id="add_new_good"
                                                       value='1' onClick={bindHandler} disabled={!anomalys.length>0}>
                                                   Связать со значением
                                               </button>
                                           </div>
                                       </div>
                                   </div>


                           </div>
                           </form>
                           <div className="row w-100 mx-0 px-1 position-absolute bottom-0 start-50 translate-middle-x">
                               <div className="col-12 d-flex justify-content-end px-4">
                                   <input type="button" className="btn col-3 text-white px-0 my-3" style={{backgroundColor:'#2F2F2F'}} name="save" id="save"
                                          value='Проверить изменения' onClick={()=> setModalActive(true)}/>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
                {user.isAdmin &&
                <div className="mx-auto" style={{height:'35vh', marginTop:'5vh', marginBottom:'5vh'}}>
                    <div className="row px-5 py-2" style={{height:'100%'}} id="alert">
                        <div className="col-12 text-bg-light rounded shadow-sm py-3" >
                            <div className="row mb-3">
                                <div className="col-6">
                                    <h4 className="text-center">Загрузить словарь</h4>
                                </div>
                                <div className="col-6">
                                    <h4 className="text-center">Выгрузить словарь</h4>
                                </div>
                            </div>
                            <div className="hstack gap-2 align-items-start">
                                <div className="px-4" style={{width: '50%'}}>
                                    <Dropzone />
                                </div>
                                <div className="vr p-0"/>
                                <div className="px-4 " style={{width: '50%'}}>
                                    <ExportCSV fileName="Словарь" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
           </div>
            <Modal active={modalActive} setActive={setModalActive} substitutionAdd={substitutionAdd} setSubstitutionAdd={setSubstitutionAdd} referenceAdd={referenceAdd} setReferenceAdd={setReferenceAdd} anomalys={anomalys} setAnomalys={setAnomalys}>
            </Modal>
        </>
    )
}
export default AnomalyShort;