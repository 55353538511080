import React from 'react';
import Modal from 'react-modal';
import '../../index.css';
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../hook/useAuth";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth:"500px",
        width: "100%",
        padding: "50px",
        fontSize: "16px"
    },
    overlay:{
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        display: 'flex',
        zIndex: '10000'
    }
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)

export const TimeoutWarningModal = ({isOpen, onRequestClose}) => {

    const navigate = useNavigate();
    const {signOut} = useAuth();

    const onLogOffCall = () => {
        signOut(()=>{navigate('/')});
    }


    return (
        <div>
            <Modal
                isOpen={isOpen}
                style={customStyles}

            >
                <h2>Время сессии подходит к концу</h2>
                <div>Выход произойдет автоматически через 15 минут</div>
                <br/>
                <button className="btn btn-dark" onClick={onLogOffCall}>Выйти сейчас</button>
                <button className="btn btn-success mx-2" onClick={onRequestClose}>Хорошо</button>
            </Modal>
        </div>
    );
}