import React, {useContext} from 'react';
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../hook/useAuth'
import {ModalContext} from "../../hoc/ModalProvider";

const NewNavBar=()=>{
  const {user, signOut}= useAuth();
  const navigate = useNavigate();
  const {setSaveModalOpen, setSaveModalOpenRedirect, setHref} = useContext(ModalContext);

  const exitHandler = () =>{
    var data = JSON.parse(localStorage.getItem('changes'));
    var changes = localStorage.getItem('changes_m');
    var now_change = Number(data);
    if(now_change === 0 && changes !== 'true'){
      signOut(()=>{navigate('/')});
    }else {
      setSaveModalOpen(true);
    }
  }  
  const anomalyHandler = ()=> {
    var data = JSON.parse(localStorage.getItem('changes'));
    var now_change = Number(data);
    if(now_change!==0){
      setHref('/manager')
      setSaveModalOpenRedirect(true);
    } else {      
      navigate("/manager")
    }
  }
  const managerHandler = ()=> {
    var changes = localStorage.getItem('changes_m');
    if(changes==='true'){
        setHref('/anomaly')
        setSaveModalOpenRedirect(true);
    } else {      
      navigate("/anomaly")
    }
  }

    return (
      <>
        <nav className="navbar navbar-expand-lg bg-light fixed-top" >
          <div className="container-fluid px-5">
            <a className="navbar-brand" href="#">
              <img src="images/logoo.png" alt="AAC" width="auto" height="40"/>
            </a>
            <div className="collapse navbar-collapse" id="navbarText">
              <ul className="navbar-nav me-auto mb-2 mx-2 mb-lg-0">
                {user.isAdmin &&
                  <li className="nav-item">
                    <button onClick={anomalyHandler} className="btn">
                      Менеджеры
                    </button>
                  </li>
                }
                <li className="nav-item">
                  <button onClick={managerHandler} className="btn">
                    Аномалии
                  </button>
                </li>
              </ul>
              <div className="navbar-text d-flex">
                <a href="#" style={{textDecoration:'none'}} className="mx-4  ">{user.full_name}</a>
                <button onClick={exitHandler} className="likelink"> Выйти
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       className="bi bi-box-arrow-right mx-2 mb-1" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"/>
                    <path fill-rule="evenodd"
                          d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </nav>
      </>
    )
}
export default NewNavBar