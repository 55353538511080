import React, {createContext, useEffect, useState} from "react";
import {NonSavedWarningModal} from "../components/modals/nonsavedWarningModal";
import { NonSavedWarningModalredirect } from "../components/modals/nonsavedWarningModalredirect";


export const ModalContext = createContext(null);


export const ModalProvider = ({children})=>{

    const [isSaveModalOpen, setSaveModalOpen] = useState(false);
    const [isSaveModalOpenRedirect, setSaveModalOpenRedirect] = useState(false);

    const [isHref, setHref] = useState(null);

    const value = {setSaveModalOpen, setSaveModalOpenRedirect, setHref}


    return (
        <ModalContext.Provider value={value}>
            {children}
            {isSaveModalOpen && (
                <NonSavedWarningModal
                    isOpen={isSaveModalOpen}
                    onRequestClose={() => setSaveModalOpen(false)}
                >
                </NonSavedWarningModal>
            )}
            {isSaveModalOpenRedirect && (
                <NonSavedWarningModalredirect
                    isOpen={isSaveModalOpenRedirect}
                    onRequestClose={() => setSaveModalOpenRedirect(false)}
                    href={isHref}
                >
                </NonSavedWarningModalredirect>
            )}

        </ModalContext.Provider>
    );
};