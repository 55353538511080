import React, {useCallback, useEffect, useState, useRef, useContext} from 'react';
import NewNavBar from "../components/UI/NewNavBar";
import ManagersList from "../components/managersList";
import {$authHost} from "../http";
import {useHttp} from "../hook/useHttp";
import LoadingSpinner from "../components/modals/LoadingSpinner";
import {useAuth} from "../hook/useAuth";
import {useNavigate} from "react-router-dom";
import {ModalContext} from "../hoc/ModalProvider";



const Manager = () => {

    const {request, loading} = useHttp()
    const {user, signOut} = useAuth();
    const {navigate} = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [isChange, setChange] = useState(false);


    const [managers, setManagers] = useState([]);
    const [pharmacyChains, setPharmacyChains] = useState([]);
    const [managersChains, setManagersChains] = useState([]);
    const [availableChans, setAvailableChains] = useState([]);
    const [currentManager_id, setCurrentManager_id] = useState(null);

    const changeselectHandler = (event) => {

        var manager_id = event.target.value;
        setCurrentManager_id(manager_id);
    }

    window.onbeforeunload = function() {
        var changes = localStorage.getItem('changes_m');
        if(isChange){
            return false;
        }
    };


    const getManagers = useCallback(async ()=>{
        try{
            setIsLoading(true);
            const fetched = await $authHost.get(`/managers-getAll`).then(res => {
                const managers = res.data;
                setManagers(managers);
            })
        } catch (e){

        }finally {
            setIsLoading(false);
        }
    }, [])


    const unsaveHandler = (event)=>{
        setChange(true);
        localStorage.setItem("changes_m", "true");
    }



    const getPharmacyChains = useCallback(async ()=>{
        try{
            setIsLoading(true);
            const fetched = await $authHost.get(`/pharmacy_chains-getAll`).then(res => {
                const chains = res.data;
                setPharmacyChains(chains);
            })
        } catch (e){

        }finally {
            setIsLoading(false);
        }
    }, [])

    const getChainsByManager = useCallback(async ()=>{
        try{
            setIsLoading(true);
            const fetched = await $authHost.get(`/pharmacy_chains-by_manager?user_id=${currentManager_id}`).then(res => {
                if(res.data.status_code === 500){
                    setManagersChains([]);
                }
                else {
                    const chains_by_m = res.data;
                    setManagersChains(chains_by_m);
                }

            })
        } catch (e){

        }finally {
            setIsLoading(false);
        }
    }, [currentManager_id])

    const getAvailableChains = useCallback(async ()=>{
        try{
            setIsLoading(true);
            const fetched = await $authHost.get(`/pharmacy_chains-free`).then(res => {
                const chains = res.data;
                setAvailableChains(chains);
            })

        } catch (e){

        }finally {
            setIsLoading(false);
        }
    }, [currentManager_id])


    const saveHandler = async () => {
        // setIsLoading(true);
        var d = document.querySelector('form');
        var formData = new FormData(d);
        var managers_chains = [];
        formData.forEach(element => {
            managers_chains.push(
                {
                    id_user : currentManager_id,
                    id_pharmacy: element
                }
            );
        });
        try {
            const data = await $authHost.post(`/manages-pharmacy_chains-add?id_user=${currentManager_id}`, {manages_pharmacy_chains: managers_chains})
                .then(response => {
                Progress_alert(response.data)
            });
        } catch (e) {}
        // finally {setIsLoading(false); setChange(false);}

    }

    async function Progress_alert(uuid){
        let Response_message="";
        let Response_progress=0
        if(uuid!=null){


            const container = document.createElement('div');
            container.classList.add("progress-container");
            container.id = "progress-container-id";
            document.getElementById("root").append(container);
            const wrapper = document.createElement('div');
            wrapper.classList.add("progress-wrapper");
            wrapper.id = "alert_place";
            wrapper.innerHTML = [
                `<div class="alert alert-success alert-dismissible fade show mt-3" id="alert_progress" role="alert">`,
                `   <div id="before_status"><strong>Сохранение изменений</strong></div>`,
                `   <div class="progress">`,
                `   <div class="progress-bar bg-success progress-bar-striped" role="progressbar" id="query_progress" style=" width: 0%"></div>`,
                `</div>`,
                '</div>'
            ].join('')
            container.appendChild(wrapper);

            console.log(Response_message);
            while (1) {
                const data = await $authHost.get(`/task-status-get?task=${uuid}`).then(response => {
                    Response_message=response.data.status
                    Response_progress=response.data.progress
                });
                console.log(Response_message);
                const bar = document.getElementById('query_progress');
                bar.style.width = String(Response_progress)+"%";
                if (Response_message === "queued") {
                    if (!document.getElementById('status_mess')){
                        const mess = document.createElement('div');
                        mess.innerHTML = [
                            `<div id="status_mess">Ваш запрос в очереди....</div>`,
                        ].join('')
                        document.getElementById('before_status').after(mess);
                    }
                }
                if (Response_message === "started") {
                    if (document.getElementById('status_mess')){
                        document.getElementById('status_mess').remove();
                    }
                }
                if (Response_message === "failed") {
                    const appendAlert_u = () => {

                        const dang_wrapper = document.createElement('div');
                        dang_wrapper.classList.add("progress-wrapper");
                        dang_wrapper.id = "alert_place";
                        dang_wrapper.innerHTML = [
                            `<div class="alert alert-danger alert-dismissible fade show mt-3" role="alert">`,
                            `   <div>Призошла ошибка сохранения...</div>`,
                            '</div>'
                        ].join('');
                        container.replaceChild(dang_wrapper, wrapper);

                    }
                    appendAlert_u();
                    await new Promise(r => setTimeout(r, 2000));
                    break;
                }
                if (Response_message === "finished") {
                    setChange(false);
                    break;
                }
                await new Promise(r => setTimeout(r, 2000));
            }
            console.log(document.getElementById('progress-container-id'))
            document.getElementById('alert_place').remove();
            document.getElementById('progress-container-id').remove();
        }

    }


    const validation = () => {
        var manager = document.getElementById("manager").value;
        if (manager === 0) {
            alert('Недопустимая комбинация в поле менеджера');
        } else{
           saveHandler();
           localStorage.removeItem('changes_m');
        }
    }

    useEffect( () =>{
        getManagers()
        getPharmacyChains()
        getChainsByManager()
        getAvailableChains()
    }, [getManagers, getPharmacyChains, getChainsByManager, getAvailableChains])


    return (
        <>
            <NewNavBar/>
            <div className="row" style={{height:'100vh'}}>
                <div className="my-auto" style={{height:'80vh'}}>
                    <div className="row px-5 py-2" style={{height:'50%'}}>
                        <div className="col-12 text-bg-light rounded shadow-sm py-3 px-4">
                            <div className="row mb-2">
                                <div className="col-6">
                                    <h4 className="text-center">Менеджеры</h4>
                                </div>
                                <div className="col-6">
                                    <h4 className="text-center">Аптечные сети</h4>
                                </div>
                            </div>
                            <form>
                                <div className="hstack gap-2">
                                    <div className="px-4" style={{width:'50%', height:'30vh'}}>
                                        <p className="h6 mt-0 mb-2">Выберите менеджера:</p>
                                        <select className="form-control" id="manager" name="" disabled={isChange} onChange={changeselectHandler} size="10">
                                            <ManagersList managers={managers}/>
                                        </select>
                                        {isChange &&
                                            <div id="emailHelp" className="form-text">
                                                Сохраните изменения!
                                            </div>
                                        }
                                    </div>
                                    <div className="vr p-0"/>
                                    <div className="px-4" style={{width:'50%', height:'30vh'}}>
                                        <p className="h6">Назначьте сеть:</p>
                                        <div className="" style={{}} >
                                            {currentManager_id && pharmacyChains && availableChans && managersChains &&
                                            pharmacyChains.map((pharmacyChain, index) => {
                                                if((managersChains.concat(availableChans)).includes(pharmacyChain.PharmacyChain_ID))
                                                {
                                                    return(
                                                        <>
                                                            <div className="form-check">
                                                                <input className="form-check-input"
                                                                       name="chain"
                                                                       type="checkbox"
                                                                       onChange={unsaveHandler}
                                                                       value={`${pharmacyChain.PharmacyChain_ID}`}
                                                                       defaultChecked={managersChains.includes(pharmacyChain.PharmacyChain_ID)}
                                                                />
                                                                <label className="form-check-label" htmlFor={`${pharmacyChain.PharmacyChain_FullName}`}>
                                                                    {pharmacyChain.PharmacyChain_FullName}
                                                                </label>
                                                            </div>
                                                        </>
                                                    );
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-end">
                                    <div className="col-6 d-flex flex-row-reverse px-4">
                                        <button type="button" className="btn col-6 text-white mt-3" style={{backgroundColor:'#2F2F2F'}} name="save" id="save"
                                                onClick={validation}>
                                            Сохранить изменения
                                        </button>

                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
export default Manager