import React, {createContext, useState} from "react";
import LoadingSpinner from "../components/modals/LoadingSpinner";
import LoadingScreen from "../components/modals/LoadingScreen";
import {LoadingProgress} from "../components/modals/LoadingProgress";

export const LoadContext = createContext(null);


export const LoadProvider = ({children})=>{

    const [isLoading, setIsLoading] = useState(false);



    var firstview = !!localStorage.getItem("firstview")? (localStorage.getItem("firstview") === 'true') : true;

    const value = {setIsLoading}

    return (
        <LoadContext.Provider value={value}>
            {children}
            {(firstview && isLoading) && <LoadingScreen /> }
            {(!firstview && isLoading) && <LoadingSpinner /> }
        </LoadContext.Provider>
    );
};