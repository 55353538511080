import {useLocation, Navigate} from 'react-router-dom'
import {useAuth } from '../hook/useAuth'

const RequareAuth= ({children})=>{
    const {user} = useAuth();

    if(!user){
        return <Navigate to='/'/>
    }

    return children

}

export  {RequareAuth}