import React from 'react';
import Modal from 'react-modal';
import '../../index.css';
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../hook/useAuth";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth:"500px",
        width: "100%",
        padding: "50px",
        fontSize: "16px"
    },
    overlay:{
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        display: 'flex',
        zIndex: '10000'
    }
};

export const NonSavedWarningModalredirect = ({isOpen, onRequestClose, href}) => {

    const navigate = useNavigate();

    const onLogOffCall = () => {
        onRequestClose()
        navigate(href);
        localStorage.setItem('changes', "0");
        localStorage.removeItem('changes_m');

    }

    return (
        <div>
            <Modal
                isOpen={isOpen}
                style={customStyles}
            >
                <h2>Изменения не сохранены!</h2>
                <div>Несохраненные изменения будут потеряны</div>
                <br/>
                <button className="btn btn-dark" onClick={onLogOffCall}>Перейти без сохранения</button>
                <button className="btn btn-success mx-2" onClick={onRequestClose}>Не переходить</button>
            </Modal>
        </div>
    );
}