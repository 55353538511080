import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom'
 
const ManagersList=({managers})=>{
    return (
      <>
        {managers && managers.map((manager, index) => {
          return(
              <>
                  <option className="" key={index} value={`${manager.id_user}`}>
                      {manager.full_name}
                  </option>
              </>
          );
        })}
      </>
    )
}
export default ManagersList;