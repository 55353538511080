import {useLocation, Navigate} from 'react-router-dom'
import {useAuth } from '../hook/useAuth'

const AdminAuth= ({children})=>{
    const {user} = useAuth();

    if(!user || !user.isAdmin){
        return <Navigate to='/'/>
    }

    return children

}

export  {AdminAuth}