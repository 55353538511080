import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom'
 
const Anomalylist=({anomalys, selected})=> {

    return (
        <>
            {anomalys && anomalys.map((anomaly, index) => {
                return (
                    <>
                        <option key={index} selected={selected === anomaly.Product_Name} className="my-1"
                                value={`${anomaly.ID}`}> {anomaly.Product_Name}</option>
                    </>

                );
            })}
        </>
    )
}
export default Anomalylist