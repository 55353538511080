import React, {createElement, useCallback, useContext, useState} from "react";
import {$authHost} from "../http";
import "./modal.css";
import {useAuth} from "../hook/useAuth";
import LoadingSpinner from "../components/modals/LoadingSpinner";
import {LoadContext} from "../hoc/LoadProvider";


const Modal=({active, setActive, substitutionAdd, setSubstitutionAdd, referenceAdd, setReferenceAdd, anomalys, setAnomalys})=>{

    const {user} = useAuth();
    // const {setIsLoading} = useContext(LoadContext);

    const save = async() =>{
        var subs = false;
        var refs = false;
        // setIsLoading(true);
        // if(substitutionAdd.length>0){
        //     try {
        //         const data = await $authHost.post(`/substitution-add?id_user=${user.user_ID}`, {substitution: substitutionAdd})
        //             .then(response => {
        //                 const message = response.data.message
        //                 if (message == "success") {
        //                     setActive(false)
        //                     //localStorage.setItem("changes", String(0));
        //                     //setSubstitutionAdd([]);
        //                     setQuery([...query, response.data]);
        //                 }
        //             });
        //     } catch (e) {}finally {
        //         subs= true;
        //     }
        // }
        // if(referenceAdd.length>0){
        //     try {
        //         const data = await $authHost.post(`/reference-add?id_user=${user.user_ID}`, {reference: referenceAdd})
        //             .then(response => {
        //                 const message = response.data.message
        //                 if (message == "success") {
        //                     setActive(false)
        //                     //localStorage.setItem("changes", String(0));
        //                     // setReferenceAdd([]);
        //                     setQuery([...query, response.data]);
        //                 }
        //             }).catch()
        //     } catch (e) {}finally{refs = true;};
        // }
        // if(referenceAdd.length>0 && substitutionAdd.length>0){
        //     if(subs===true && refs===true){
        //         setIsLoading(false);
        //     }
        // }else if(referenceAdd.length>0 && substitutionAdd.length===0){
        //     if(refs === true){
        //         setIsLoading(false);
        //     }
        // }else if(referenceAdd.length===0 && substitutionAdd.length>0){
        //     if(subs === true){
        //         setIsLoading(false);
        //     }
        // }else{
        //     setIsLoading(false);
        // }


        try {
            const data = await $authHost.post(`/substitution-add?id_user=${user.user_ID}`, {substitution: substitutionAdd, reference: referenceAdd})
                .then(response => {
                    setActive(false);
                  Progress_alert(response.data);
                });
        } catch (e) {}
    }

    async function Progress_alert(uuid){
        let Response_message="";
        let Response_progress=0
        if(uuid!=null){


                const container = document.createElement('div');
                container.classList.add("progress-container");
                container.id = "progress-container-id";
                document.getElementById("root").append(container);
                const wrapper = document.createElement('div');
                wrapper.classList.add("progress-wrapper");
                wrapper.id = "alert_place";
                wrapper.innerHTML = [
                    `<div class="alert alert-success alert-dismissible fade show mt-3" id="alert_progress" role="alert">`,
                    `   <div id="before_status"><strong>Сохранение изменений</strong></div>`,
                    `   <div class="progress">`,
                    `   <div class="progress-bar bg-success progress-bar-striped" role="progressbar" id="query_progress" style=" width: 0%"></div>`,
                    `</div>`,
                    '</div>'
                ].join('')
                container.appendChild(wrapper);

            console.log(Response_message);
            while (1) {
                const data = await $authHost.get(`/task-status-get?task=${uuid}`).then(response => {
                    Response_message=response.data.status
                    Response_progress=response.data.progress
                });
                console.log(Response_message);
                const bar = document.getElementById('query_progress');
                bar.style.width = String(Response_progress)+"%";
                if (Response_message === "queued") {
                    if (!document.getElementById('status_mess')){
                        const mess = document.createElement('div');
                        mess.innerHTML = [
                            `<div id="status_mess">Ваш запрос в очереди....</div>`,
                        ].join('')
                        document.getElementById('before_status').after(mess);
                    }
                }
                if (Response_message === "started") {
                    if (document.getElementById('status_mess')){
                        document.getElementById('status_mess').remove();
                    }
                }
                if (Response_message === "failed") {
                    const appendAlert_u = () => {

                        const dang_wrapper = document.createElement('div');
                        dang_wrapper.classList.add("progress-wrapper");
                        dang_wrapper.id = "alert_place";
                        dang_wrapper.innerHTML = [
                            `<div class="alert alert-danger alert-dismissible fade show mt-3" role="alert">`,
                            `   <div>Призошла ошибка сохранения...</div>`,
                            '</div>'
                        ].join('');
                        container.replaceChild(dang_wrapper, wrapper);

                    }
                    appendAlert_u();
                    await new Promise(r => setTimeout(r, 2000));
                    break;
                }
                if (Response_message === "finished") {
                    localStorage.setItem("changes", String(0));
                    setSubstitutionAdd([]);
                    setReferenceAdd([]);
                    break;
                }
                await new Promise(r => setTimeout(r, 2000));
            }
            console.log(document.getElementById('progress-container-id'))
            document.getElementById('alert_place').remove();
            document.getElementById('progress-container-id').remove();
        }

    }



    const referenceCancel=(event)=>{
        const returnAnomaly = [...anomalys, {Product_Name: referenceAdd[event.target.value]}]
        setAnomalys(returnAnomaly)        
        const referenceTrim = referenceAdd.filter(item => item !== referenceAdd[event.target.value])
        setReferenceAdd(referenceTrim);
        var data = JSON.parse(localStorage.getItem('changes'));
        var new_change = Number(data) - 1;
        localStorage.setItem("changes", String(new_change));
    }

    const substitutionCancel=(event)=>{
        const returnAnomaly = [...anomalys, {Product_Name: substitutionAdd[event.target.value].Product_Name_Anomaly}]
        setAnomalys(returnAnomaly)        
        const referenceTrim = substitutionAdd.filter(item => item.Product_Name_Anomaly !== substitutionAdd[event.target.value].Product_Name_Anomaly)
        setSubstitutionAdd(referenceTrim);
        var data = JSON.parse(localStorage.getItem('changes'));
        var new_change = Number(data) - 1;
        localStorage.setItem("changes", String(new_change));
    }

    return(
        <div className={active ? "modal active":"modal"} onClick={()=> setActive(false)}>
            <div className={active ? "modal__content active":"modal__content"} onClick={e => e.stopPropagation()}>
                <h4 className="text-center">
                    Связанные значения
                </h4>
                <div className="scroll-table-body">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Аномалия</th>
                                <th scope="col">Эталон</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody >
                            {referenceAdd && referenceAdd.map((data, i) => {
                                return(
                                    <tr>
                                        <td value={`${data}`} key={i} className="col-4">{data}</td>
                                        <td className="col-5">
                                            <h6> Аномалия выбрана как эталон </h6>
                                        </td>                        
                                        <td className="col-3 text-end">
                                            <button type="submit" id="delete" className="btn btn-sm btn-secondary" name="delete" value={i} onClick={referenceCancel} >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg mb-1" viewBox="0 0 16 16">
                                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                                </svg>  Не добавлять
                                            </button>
                                        </td>
                                    </tr>

                                );
                            })}
                            {substitutionAdd && substitutionAdd.map((data, index) => {
                                return(
                                    <tr>
                                        <td value={`${data.Product_Name_Anomaly}`} className="col-4">{data.Product_Name_Anomaly}</td>
                                        <td value={`${data.Product_Name}`} className="col-5">{data.Product_Name}</td>
                                        <td className="col-3 text-end">
                                            <button type="submit" id="delete" className="btn btn-sm btn-secondary" name="delete" value={index} onClick={substitutionCancel} >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg mb-1" viewBox="0 0 16 16">
                                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                                </svg>  Не связывать
                                            </button>
                                        </td>

                                    </tr>

                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="d-flex justify-content-end px-1">
                    <input type="button" className="btn col-3 text-white mt-3 align-self-end" style={{backgroundColor:'#2F2F2F'}} name="save" id="save"
                           value='Сохранить изменения' onClick={save}/>
                </div>

            </div>
        </div>
    )
}

export default Modal