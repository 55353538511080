import React, {useCallback, useContext, useState} from 'react'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {$authHost} from "../http";
import {LoadContext} from "../hoc/LoadProvider";

export const ExportCSV = ({fileName}) => {

    const {setIsLoading} = useContext(LoadContext);
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const exportToCSV = async (fileName) => {

        setIsLoading(true);
        var csvData = [];
        var access_load = false;
        try {
            let pages;
                const fetched_1 = await $authHost.get(`/substitution-pages-count`).then(res => {
                    const chains = res.data;
                    pages = chains;
                });
                for(let i = 0; i < pages;i++){
                    const fetched = await $authHost.get(`/substitution?pages=${i}`).then(res => {
                        const chains = res.data;
                        chains.map((elem, index) => {
                            csvData.push(elem);
                        })
                    }).finally(()=>{
                        access_load = true;
                    });
                }
        }catch (e){}finally {
            if(access_load){
                const ws = XLSX.utils.json_to_sheet(csvData);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data']};
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], {type: fileType});
                FileSaver.saveAs(data, fileName + fileExtension);
                setIsLoading(false);
            }
        }


    }
    return (
        <div className="row">
            <label htmlFor="inputPassword5" className="form-label px-0" >Выгрузите словарь подстановок аномалий и эталонов</label>
            <button type="button" className="btn btn-success col-6 mt-1"
                    onClick={(e) => exportToCSV(fileName)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     className="bi bi-arrow-bar-down me-2" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM8 6a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 12.293V6.5A.5.5 0 0 1 8 6z"/>
                </svg>
                Экспортировать
            </button>
        </div>

    )
}