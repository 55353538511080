import React, { useCallback, useState, useContext } from "react";
// import LoadingSpinner from "./modals/LoadingSpinner";
import {read, utils, writeFile} from 'xlsx';
import {$authHost} from "../../http";
import {useAuth} from "../../hook/useAuth";
import {LoadContext} from "../../hoc/LoadProvider";
import { useDropzone } from "react-dropzone";
import "./Dropzone.css"


function Dropzone({ open }) {  
  const {user} = useAuth();
  const {setIsLoading} = useContext(LoadContext);
  
  const [files, setFiles] = useState([]);
  const maxFiles = 1;

  const onDrop = useCallback(acceptedFiles => {    
    files.forEach(existingFile => {
      acceptedFiles = acceptedFiles.filter(file => file.name !== existingFile.name) 
    });    
    let remainder = maxFiles - files.length;
    // console.log('acceptedFiles ', acceptedFiles);
    // console.log('files ', files);
    // console.log('remainder ', remainder);
    if (acceptedFiles.length > remainder && remainder > 0) {
      acceptedFiles.length = remainder
      // console.log('acceptedFiles ', acceptedFiles);

      setFiles([...files, ...acceptedFiles])
    } else if (remainder > 0) {
      setFiles([...files, ...acceptedFiles])
    }
  }, [files])
  
  const { 
    getRootProps, 
    getInputProps, 
    // acceptedFiles, 
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    maxFiles:maxFiles,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls']
    },
    onDrop
  });

  // console.log('files ', files[1]['key']);

  const removeFile = file => () => {
    const newFiles = [...files]
    newFiles.splice(newFiles.indexOf(file), 1)
    setFiles(newFiles)
  }

  const removeAll = () => {
    setFiles([])
  }

  const file = files.map(file => (
    <li key={file.path} style={{listStyleType:'none'}} className="mx-1">
        <button type="button" className="btn bg-white rounded-pill px-3 border mb-2" >
            {file.path} - {file.size} bytes
            <span className="badge text-bg-secondary rounded-pill ms-3" onClick={removeFile(file)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg"
                     viewBox="0 0 16 16">
              <path
                  d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
          </svg>
            </span>
        </button>
    </li>
  ))
  
  const saveHandler = async (event) =>{
      var csvData = [];
    if (files.length === 1) {
        const file = files[0];
        const reader = new FileReader();
        reader.onload = async (event) => {
            const wb = read(event.target.result);
            const sheets = wb.SheetNames;
            const anti_bug=[]
            if (sheets.length) {
                csvData = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                try {
                    const data = await $authHost.post(`/substitution-add?id_user=${user.user_ID}`, {substitution: csvData,reference:anti_bug})
                        .then(response => {
                            Progress_alert(response.data);
                        });
                } catch (e) {
                } finally {
                }
            }
        }
        reader.readAsArrayBuffer(file);
    }
  }

    async function Progress_alert(uuid){
        let Response_message="";
        let Response_progress=0
        if(uuid!=null){


            const container = document.createElement('div');
            container.classList.add("progress-container");
            container.id = "progress-container-id";
            document.getElementById("root").append(container);
            const wrapper = document.createElement('div');
            wrapper.classList.add("progress-wrapper");
            wrapper.id = "alert_place";
            wrapper.innerHTML = [
                `<div class="alert alert-success alert-dismissible fade show mt-3" id="alert_progress" role="alert">`,
                `   <div id="before_status"><strong>Сохранение изменений</strong></div>`,
                `   <div class="progress">`,
                `   <div class="progress-bar bg-success progress-bar-striped" role="progressbar" id="query_progress" style=" width: 0%"></div>`,
                `</div>`,
                '</div>'
            ].join('')
            container.appendChild(wrapper);

            console.log(Response_message);
            while (1) {
                const data = await $authHost.get(`/task-status-get?task=${uuid}`).then(response => {
                    Response_message=response.data.status
                    Response_progress=response.data.progress
                });
                console.log(Response_message);
                const bar = document.getElementById('query_progress');
                bar.style.width = String(Response_progress)+"%";
                if (Response_message === "queued") {
                    if (!document.getElementById('status_mess')){
                    const mess = document.createElement('div');
                    mess.innerHTML = [
                        `<div id="status_mess">Ваш запрос в очереди....</div>`,
                    ].join('')
                    document.getElementById('before_status').after(mess);
                    }
                }
                if (Response_message === "started") {
                    if (document.getElementById('status_mess')){
                        document.getElementById('status_mess').remove();
                    }
                }
                if (Response_message === "failed") {
                    const appendAlert_u = () => {

                        const dang_wrapper = document.createElement('div');
                        dang_wrapper.classList.add("progress-wrapper");
                        dang_wrapper.id = "alert_place";
                        dang_wrapper.innerHTML = [
                            `<div class="alert alert-danger alert-dismissible fade show mt-3" role="alert">`,
                            `   <div>Призошла ошибка сохранения...</div>`,
                            '</div>'
                        ].join('');
                        container.replaceChild(dang_wrapper, wrapper);

                    }
                    appendAlert_u();
                    await new Promise(r => setTimeout(r, 2000));
                    break;
                }
                if (Response_message === "finished") {
                    setFiles([])
                    break;
                }
                await new Promise(r => setTimeout(r, 2000));
            }
            console.log(document.getElementById('progress-container-id'))
            document.getElementById('alert_place').remove();
            document.getElementById('progress-container-id').remove();
        }

    }
  
  return (
    <div className="row">
          <div className="dropzone-div p-1">
              {files.length>0?
                  <aside>
                      {/*{files.length > 0 && <h6>Файлы:</h6>}*/}
                      <ul>{file}</ul>
                  </aside>
              :
                  <div {...getRootProps({ className: "dropzone" })}>
                      <input className="input-zone" {...getInputProps()} />
                      <div className="text-center">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                               className="bi bi-filetype-xlsx text-body-secondary" viewBox="0 0 16 16">
                              <path fill-rule="evenodd"
                                    d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM7.86 14.841a1.13 1.13 0 0 0 .401.823c.13.108.29.192.479.252.19.061.411.091.665.091.338 0 .624-.053.858-.158.237-.105.416-.252.54-.44a1.17 1.17 0 0 0 .187-.656c0-.224-.045-.41-.135-.56a1.002 1.002 0 0 0-.375-.357 2.028 2.028 0 0 0-.565-.21l-.621-.144a.97.97 0 0 1-.405-.176.37.37 0 0 1-.143-.299c0-.156.061-.284.184-.384.125-.101.296-.152.513-.152.143 0 .266.023.37.068a.624.624 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.093 1.093 0 0 0-.199-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.552.05-.777.15-.224.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.123.524.082.149.199.27.351.367.153.095.332.167.54.213l.618.144c.207.049.36.113.462.193a.387.387 0 0 1 .153.326.512.512 0 0 1-.085.29.558.558 0 0 1-.255.193c-.111.047-.25.07-.413.07-.117 0-.224-.013-.32-.04a.837.837 0 0 1-.249-.115.578.578 0 0 1-.255-.384h-.764Zm-3.726-2.909h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415H1.5l1.24-2.016-1.228-1.983h.931l.832 1.438h.036l.823-1.438Zm1.923 3.325h1.697v.674H5.266v-3.999h.791v3.325Zm7.636-3.325h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036l.823-1.438Z"/>
                          </svg>
                          {isDragAccept && (<p className="text-body-tertiary my-auto">Отпустите, чтобы перетащить Excel-файл сюда</p>)}
                          {isDragReject && (<p className="text-body-tertiary my-auto">Некоторые файлы будут отклонены</p>)}
                          {!isDragActive && (<p className="text-body-secondary my-auto">Перетащите сюда файл или <u>нажмите</u>, чтобы выбрать</p>)}
                      </div>
                  </div>
              }
          </div>
        <button type="button" className="btn btn-success col-6 mt-2 mx-2"
                onClick={saveHandler}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 className="bi bi-arrow-bar-up me-2" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M8 10a.5.5 0 0 0 .5-.5V3.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 3.707V9.5a.5.5 0 0 0 .5.5zm-7 2.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z"/>
            </svg>
            Сохранить
        </button>
    </div>
  );
}

export default Dropzone