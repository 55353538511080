import React, {useEffect, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom'
import { useAuth } from '../hook/useAuth';
import {$host} from "../http";


const Login=()=>{
    
    const navigate = useNavigate();

    const {signIn} = useAuth();

    const [form, setForm] = useState({
            user: '', password: ''
    })

    const changeHandler = event => {
        setForm({...form, [event.target.name]: event.target.value })
    }

    function validate(){
      // const regEx_user = (str) => /^[0-9A-Z_.@]{4,64}$/i.test(str);/.+@.+\..+/i
        const regEx_user = (str) => /.+@.+\..+/i.test(str);
        const regEx_password = (str) => /^[0-9A-Z_#$*!]{8,64}$/i.test(str);

        var login = document.getElementById("user");
        var password = document.getElementById("password");


        if (!regEx_user(login.value) && !regEx_password(password.value)){
            login.classList.add("is-invalid");
            password.classList.add("is-invalid");
            login.classList.remove("is-valid");
            password.classList.remove("is-valid");
        } else if (!regEx_user(login.value)) {
            login.classList.add("is-invalid");
            password.classList.add("is-valid");
            login.classList.remove("is-valid");
            password.classList.remove("is-invalid");
        } else if (!regEx_password(password.value)) {
            password.classList.add("is-invalid");
            login.classList.add("is-valid");
            password.classList.remove("is-valid");
            login.classList.remove("is-invalid");
        } else {
          return true;
        }
  }




    const handlerSubmit = async (event) =>{
        event.preventDefault();
        const myValidation = validate()
        if (myValidation===true) {

            try {
                const data = await $host.get(`/login?email=${form.user}&password=${form.password}`)
                    .then(response => {
                        const message = response.data.message
                        if (message != "Нет такого пользоваля") {
                            signIn({
                                user_ID: response.data[0],
                                full_name: response.data[3],
                                isAdmin: response.data[2]
                            }, response.data[4]);
                            navigate("/anomaly");
                        } else {
                            const alertPlaceholder = document.getElementById('liveAlertPlaceholder')
                            const appendAlert = (message, type) => {
                                const wrapper = document.createElement('div')
                                wrapper.innerHTML = [
                                    `<div class="alert alert-secondary alert-dismissible fade show mt-3" role="alert">`,
                                    `   <div>Такого пользователя не существует</div>`,
                                    '   <button type="button" class="btn-close" data-bs-dismiss="alert" onclick="" aria-label="Close"></button>',
                                    '</div>'
                                ].join('')
                                alertPlaceholder.append(wrapper)
                            }
                            appendAlert();
                        }
                    });
            } catch (e) {
            }
        }

    }

    useEffect(() => {
        if (!!JSON.parse(localStorage.getItem('userData'))) {
            navigate("/anomaly")}
    }, []);

    return (
        <div className="row" style={{height:'100vh'}}>
            <div className="w-50 h-50 mx-auto my-auto">
                <div className="text-bg-light p-4 rounded shadow" id="form">
                    <img className="mx-auto d-block mb-3" src="images/logoo.png"/>
                    <h4 className="d-flex justify-content-center text-secondary mb-1">
                        Авторизация
                    </h4>
                    <form onSubmit={handlerSubmit}  className='mt-1 needs-validation' noValidate>

                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label text-secondary">Логин</label>
                            <input type="email" name='user' onChange={changeHandler} className="form-control" id="user"/>
                            <div className="invalid-feedback">
                                Недопустимые комбинация в логине
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputPassword1" className="form-label text-secondary">Пароль</label>
                            <input required type="password" name='password' onChange={changeHandler} className="form-control" id="password"/>
                            <div className="invalid-feedback">
                               недопустимая комбинация в пароле
                            </div>
                        </div>
                        <button type="submit"  className="btn col-12 text-white mt-3 btn-secondary">Войти</button>
                    </form>
                </div>
                <div id="liveAlertPlaceholder"/>
            </div>
        </div>

    )
}
export default Login